import React from "react";
import styled from "styled-components";
import firstPic from "../imgs/a5848cf9-a3b2-4097-b0d7-8939963e99bc.jpg";

const Body = () => {
  return (
    <Main>
      <img
        src="https://cdn5.f-cdn.com/contestentries/265507/14720579/55e45eb471824_thumb900.jpg"
        alt=""
        className="first-img"
      />
      <Text>
        <p>
          Welcome to <span className="name">Cherry</span> website! I'm thrilled
          to have you here. Explore, connect, and discover more about me and my
          passions. Thank you for visiting
        </p>
      </Text>
    </Main>
  );
};
const Main = styled.div`
  height: calc(100vh - 74px);
  background: linear-gradient(to bottom, black 46%, white);
  & .first-img {
    width: 100%;
  }
`;
const Text = styled.div`
  text-align: center;
  padding: 0 10px;
  transform: translateY(50%);
  line-height: 2;
  font-weight: bold;
  color: #ccc;
  & .name {
    color: #08d364;
  }
`;
export default Body;
