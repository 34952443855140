import React from "react";
import styled from "styled-components";

const Header = () => {
  return (
    <Main>
      <img
        src="https://i.pinimg.com/originals/bb/c9/a0/bbc9a03092947e038fa8ac5db7ec6c8e.jpg"
        alt=""
        className="logo"
      />

      <a href="http://wa.me/+971553286629" className="whats">
        <img
          alt="whats"
          className="whats-img"
          src="https://cdn2.iconfinder.com/data/icons/social-messaging-ui-color-shapes-2-free/128/social-whatsapp-circle-512.png"
        />
      </a>
    </Main>
  );
};
const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: linear-gradient(to right, black 45%, #08d364);
  & .whats-img {
    width: 50px;
  }
  & .logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;
export default Header;
